<template lang="pug">
div
  page-header(:title='title', :url='url', :titleNew='titleNew')
  grid(:url='urlDataSource', :columns='columns', :grid='{height:500}')
</template>

<script>
import PageHeader from '@/components/PageHeader'
import Grid from '@/components/Grid'

import GridTemplate from '@/mixins/GridTemplate'
import Permission from '@/mixins/Permission'

export default {
  mixins: [GridTemplate, Permission],
  components: {
    PageHeader,
    Grid
  },
  data () {
    return {
      title: 'Tipo de Emprestimo',
      titleNew: '',
      url: '/admin/tipo-de-emprestimo/',
      urlDataSource: `${this.$url}/tipo-emprestimo?all`,
      columns: [{
        field: 'nome',
        title: 'Título',
        width: 400
      }, {
        field: 'status',
        title: 'Ativo',
        width: 120
      }
      ]
    }
  },
  methods: {
    verifPermissao () {
      this.titleNew = this.ACTION.c ? 'Novo' : ''
      if (this.ACTION.e) {
        this.columns.push({
          field: 'ativo',
          title: 'Ações',
          width: 105,
          template: this.commandTemplate
        })
      }
    }
  },
  created () {
    this.verifPermissao()
  }
}
</script>

<style lang="stylus" scoped>

</style>
